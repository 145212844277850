import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import "./ServiceCard.scss";
import serviceOne from "./../../assets/images/service-01.svg";
import serviceTwo from "./../../assets/images/service-02.svg";
import serviceThree from "./../../assets/images/service-03.svg";
// import serviceFour from "./../../assets/images/service-04.svg";

const ServiceCard = () => {
  return (
    <Container className="service-card">
      <Row>
        <Col className="d-flex flex-grow justify-content-between">
          <Card>
            <Card.Body>
              <Card.Title>Residential cleaning</Card.Title>
              <div className="service-icon">
                <Card.Img src={serviceOne} varient="bottom" />
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Commercial cleaning</Card.Title>
              <div className="service-icon">
                <Card.Img src={serviceTwo} varient="bottom" />
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Carpet cleaning</Card.Title>
              <div className="service-icon">
                <Card.Img src={serviceThree} varient="bottom" />
              </div>
            </Card.Body>
          </Card>
          {/* <Card>
            <Card.Body>
              <Card.Title>Move-In / Move-Out cleaning</Card.Title>
              <div className="service-icon">
                <Card.Img src={serviceFour} varient="bottom" />
              </div>
            </Card.Body>
          </Card> */}
        </Col>
      </Row>
    </Container>
  );
};

export default ServiceCard;
