import React from "react";
import "./Footer.scss";
const Footer = () => {
  return (
    <div id="footer" className="footer-container">
      <div>© 2021 VS Janico. All Rights Reserved.</div>
      <div>
        <a href="#!">Privacy policy </a>|<a href="#!">Terms of use</a>
      </div>
    </div>
  );
};

export default Footer;
