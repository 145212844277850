import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Container,
  Image,
  Form,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhoneAlt,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import ContDivider from "./../../assets/images/cont-divider.png";
import "./Contact.scss";
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().email().required(),
  message: yup.string().required(),
});

const Contact = () => {
  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSucess] = useState("");
  const [isLoading, setLoading] = useState(false);
  const handleForm = (values, { resetForm }) => {
    setFormError("");
    setFormSucess("");
    setLoading(true);

    var bodyFormData = new FormData();
    bodyFormData.append("name", values.name);
    bodyFormData.append("email", values.email);
    bodyFormData.append("phone", values.phone);
    bodyFormData.append("message", values.message);

    axios({
      method: "post",
      url: "https://vsjanico.com/actions/contact.php",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response && response.data) {
          //Set error message from server
          if (response.data.error) {
            setFormError(response.data.message);
          }
          //Set success message from server
          if (!response.data.error) {
            //Reset the form after successful submission
            resetForm();
            setFormSucess(response.data.message);
          }
        }
        setLoading(false);
      })
      .catch(function (response) {
        setLoading(false);
        setFormError("something went wrong");
      });
  };
  //http://dev-vsjanico.balamahesh.com/
  //Claer the error and success message
  useEffect(() => {
    if (formError !== "" || formSuccess !== "") {
      setTimeout(() => {
        setFormError("");
        setFormSucess("");
      }, 5000);
    }
  }, [formError, formSuccess]);
  return (
    <div id="contact" className="contact pt-4">
      <Container>
        <Row>
          <Col lg={7} className="text-center">
            <h2>Contact us</h2>
            <div className="cont-label pt-3">
              <div className="pt-3">
                <FontAwesomeIcon icon={faPhoneAlt} style={{ color: "#FFF" }} />
                <strong className="pl-2"> Call us:</strong>
              </div>
              <a href="tel:+1 204-818-8709">+1 204-818-8709</a>
            </div>
            <div className="cont-divider"></div>
            <Image className="pt-5 pb-5" src={ContDivider} alt="" width="250" />
            <div className="cont-label">
              <div>
                <FontAwesomeIcon icon={faEnvelope} style={{ color: "#FFF" }} />
                <strong className="pl-2"> Mail us:</strong>
              </div>
              <a href="mailto:info@vsjanico.com">info@vsjanico.com</a>
            </div>
          </Col>
          <Col lg={4} className="mb-5 contact-form">
            <Formik
              validationSchema={schema}
              onSubmit={handleForm}
              initialValues={{
                name: "",
                email: "",
                phone: "",
                message: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group controlId="formBasicName">
                    <Form.Label>Name *</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      name="name"
                      placeholder="Enter name"
                      isInvalid={touched.name && errors.name}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address *</Form.Label>
                    <Form.Control
                      type="email"
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      placeholder="Enter email"
                      isInvalid={touched.email && errors.email}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formBasicPhone">
                    <Form.Label>Phone number *</Form.Label>
                    <Form.Control
                      type="number"
                      onChange={handleChange}
                      value={values.phone}
                      name="phone"
                      placeholder="Enter phone number"
                      isInvalid={touched.phone && errors.phone}
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      maxLength="12"
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Message *</Form.Label>
                    <Form.Control
                      as="textarea"
                      onChange={handleChange}
                      value={values.message}
                      name="message"
                      rows={3}
                      isInvalid={touched.message && errors.message}
                    />
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button variant="white" disabled={isLoading} type="submit">
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </>
                    ) : (
                      <>
                        Submit
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          style={{ color: "#005EA9" }}
                          className="ml-2"
                        />
                      </>
                    )}
                  </Button>
                  {formSuccess && <Alert color="success">{formSuccess}</Alert>}
                  {formError && <Alert color="danger">{formError}</Alert>}
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
