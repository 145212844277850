import React, { useState } from "react";
import { Navbar, Image, Nav, Button, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faInstagram,
  faFacebookF,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "./Header.scss";
import logo from "./../../assets/images/logo.svg";
import logoTwo from "./../../assets/images/logo-02.png";
import { Link } from "react-scroll";


const Header = () => {
  
  function handleClick(e) {
    e.preventDefault();
    console.log('The link was clicked.');
  }

  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Col sm={12} className="top-bar p-0" id="home">
        <Nav className="container pr-0">
          <Nav.Item className="d-flex align-items-center mr-3">
            <FontAwesomeIcon icon={faPhoneAlt} style={{ color: "#FFF" }} />
            <Nav.Link href="tel:+1 204-818-8709" className="p-0 ml-2 mr-2">
              +1 204-818-8709
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="d-flex align-items-center">
            <FontAwesomeIcon icon={faEnvelope} style={{ color: "#FFF" }} />
            <Nav.Link href="mailto:info@vsjanico.com" className="p-0 ml-2 mr-2">
              info@vsjanico.com
            </Nav.Link>
          </Nav.Item>
          <div className="ml-auto">
            <Button variant="link"
              onClick={handleClick}
            >
              <FontAwesomeIcon icon={faTwitter} style={{ color: "#FFF" }} />
            </Button>
            <Button variant="link">
              <FontAwesomeIcon icon={faInstagram} style={{ color: "#FFF" }} />
            </Button>
            <Button variant="link">
              <FontAwesomeIcon icon={faFacebookF} style={{ color: "#FFF" }} />
            </Button>

            <Button variant="link" className="pr-0">
              <FontAwesomeIcon icon={faLinkedin} style={{ color: "#FFF" }} />
            </Button>
          </div>
        </Nav>
      </Col>
      <Navbar
        bg="white"
        expand="lg"
        className="container pl-0 pr-0 nav-header-bar"
        sticky="top"
        expanded={expanded}
      >
        <div className="container">
          <Navbar.Brand>
            <Image src={logo} />
            <Image src={logoTwo} className="d-none" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Link
                to="home"
                spy={true}
                smooth={true}
                className="nav-link"
                onClick={() => setExpanded(false)}
              >
                Home
              </Link>

              <Link
                to="about"
                spy={true}
                smooth={true}
                className="nav-link"
                offset={-100}
                onClick={() => setExpanded(false)}
              >
                About Us
              </Link>

              <Link
                to="services"
                spy={true}
                smooth={true}
                className="nav-link"
                offset={-100}
                onClick={() => setExpanded(false)}
              >
                Our Services
              </Link>

              {/* <Link
                to="testimonials"
                spy={true}
                smooth={true}
                className="nav-link"
                offset={-100}
                onClick={() => setExpanded(false)}
              >
                Testimonials
              </Link> */}

              <Link
                to="contact"
                spy={true}
                smooth={true}
                className="nav-link"
                offset={-100}
                onClick={() => setExpanded(false)}
              >
                Contact Us
              </Link>

              <a href="tel:+1 204-818-8709" className="ml-2 nav-link-btn">
                Call Us
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  style={{ color: "#FFF" }}
                  className="ml-2"
                />
              </a>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
