import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Services.scss";
import serviceHero from "./../../assets/images/service-hero.png";
import ServiceCardSlider from "./../ServiceCardSlider/ServiceCardSlider";

const Services = () => {
  return (
    <Container fluid id="services" className="mt-5 pt-5">
      <div className="container">
        <Row>
          <Col lg={8}>
            <div className="cont-container">
              <ScrollAnimation animateIn="fadeIn" delay={200}>
                <h3>Featured Services</h3>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn" delay={300}>
                <h2 className="mt-3 mb-3">Why choose us?</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn" delay={400}>
                {/* <h6 className="mb-3">
                  We provide the best services for your help!
                </h6> */}
                <ul>
                  <li>Fully insured</li>
                  <li>Satisfaction guaranteed</li>
                  <li>Most affordable prices</li>
                  <li>High quality equipment and supplies</li>
                  <li>Most experienced and efficient staff</li>
                </ul>
                <p>* we use the best equipment with high temperature end high pressure cleaning/extraction.</p>
              </ScrollAnimation>
            </div>
          </Col>
          <Col lg={4}>
            <div className="img-container">
              <Image src={serviceHero} alt="" fluid />
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <ServiceCardSlider />
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
