import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Image } from "react-bootstrap";
import abouteHero from "./../../assets/images/about-hero.png";
// import aboutList from "./../../assets/images/service-00.svg";
import "./About.scss";

const About = () => {
  return (
    <Container id="about">
      <Row>
        <Col lg={4}>
          <div className="img-container">
            <Image src={abouteHero} alt="" fluid />
          </div>
        </Col>
        <Col lg={6}>
          <div className="cont-container pl-3">
            <ScrollAnimation animateIn="fadeIn" delay={200}>
              <h3>About VS Janico</h3>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn" delay={300}>
              {/* <h2 className="mt-3 mb-3">
                You can depend on us to get a good services
              </h2> */}
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn" delay={400}>
              <h6 className="mb-3">
                We are VS Janico, cleaning service provider.
              </h6>
              <p>
                VS Janico is a locally owned cleaning company in Winnipeg providing excellent service to residential and commercial clients for the past 4 years.
                <br /> <br />
                The company is fully insured and bondable providing clients with worry-free cleaning services at the most affordable prices.
                <br /> <br />
                Most of our clients come to us through references from our extremely happy existing customers.
                <br /> <br /> 
              </p>
            </ScrollAnimation>
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col className="about-list mt-5">
          <ListGroup>
            <ListGroup.Item>
              <div className="list-title">
                <Image src={aboutList} />
                <h5>Customer Focused Reviews</h5>
              </div>
              <p>
                You and your business deserve quality, on-time, consistent and
                reliable janitorial services. Put your best foot forward each
                day and impress your clients and customers with a clean and
                hygienic workplace.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="list-title">
                <Image src={aboutList} />
                <h5>We Are Committed</h5>
              </div>
              <p>
                As experts in the industry for 25 years, we execute and manage
                all aspects of janitorial services including office cleaning,
                floor waxing, carpet cleaning, and washroom maintenance.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="list-title">
                <Image src={aboutList} />
                <h5>Quality Work</h5>
              </div>
              <p>
                Our focus is and has always been on quality assurance and doing
                everything we can to ensure that our janitorial cleaning
                services are done right, on time and on budget the first time
                around, so that your business never has to look for anotheer
                commercial cleaning company ever again.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="list-title">
                <Image src={aboutList} />
                <h5>100% Customer Satisfaction</h5>
              </div>
              <p>
                You and your business deserve quality, on-time, consistent and
                reliable janitorial services. Put your best foot forward each
                day and impress your clients and customers with a clean and
                hygienic workplace.
              </p>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row> */}
    </Container>
  );
};

export default About;
