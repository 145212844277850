import React from "react";
import { Carousel, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import heroOne from "./../../assets/images/hero-01.jpg";
import heroTwo from "./../../assets/images/hero-02.png";
import heroThree from "./../../assets/images/hero-03.jpg";
import heroFour from "./../../assets/images/hero-04.jpg";
import "./HeroCarousel.scss";

// import { AnimateOnChange } from "react-animation";

const HeroCarousel = () => {
  return (
    <Carousel>
      <Carousel.Item interval={10000}>
        <div className="img-overlay"></div>
        <img className="d-block w-100" src={heroOne} alt="First slide" />
        <div className="caption-container">
          <Carousel.Caption>
            <h1>
              For all your  <span>residential and commercial</span> cleaning needs
            </h1>
            <p>
              We are a fully insured professional cleaning company offering all types of cleaning services.
            </p>
            <Button variant="white" className="ml-2">
              Learn More
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#005EA9" }}
                className="ml-2"
              />
            </Button>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <div className="img-overlay"></div>
        <img className="d-block w-100" src={heroTwo} alt="Third slide" />
        <div className="caption-container">
          <Carousel.Caption>
            <h1>
              <span>Residential</span> cleaning
            </h1>
            <p>
              We offer regular, one time, move-in, move-out or casual cleaning services for your home. 
            </p>
            <Button variant="white" className="ml-2">
              Learn More
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#005EA9" }}
                className="ml-2"
              />
            </Button>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="img-overlay"></div>
        <img className="d-block w-100" src={heroThree} alt="Third slide" />
        <div className="caption-container">
          <Carousel.Caption>
            <h1>
              <span>Commercial</span> cleaning
            </h1>
            <p>
              We offer regular, one time or casual cleaning services for tour office, warehouse, construction site or any other commercial properties. 
            </p>
            <Button variant="white" className="ml-2">
              Learn More
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#005EA9" }}
                className="ml-2"
              />
            </Button>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="img-overlay"></div>
        <img className="d-block w-100" src={heroFour} alt="Third slide" />
        <div className="caption-container">
          <Carousel.Caption>
            <h1>
              <span>Carpet</span> cleaning
            </h1>
            <p>
              We offer professional carpet cleaning/ extraction services for all types of residential and commercial properties using the best equipment. 
            </p>
            <Button variant="white" className="ml-2">
              Learn More
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#005EA9" }}
                className="ml-2"
              />
            </Button>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default HeroCarousel;
