import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "react-bootstrap";
import "./ServiceCardSlider.scss";
import serviceOne from "./../../assets/images/service-01.svg";
import serviceTwo from "./../../assets/images/service-02.svg";
import serviceThree from "./../../assets/images/service-03.svg";
// import serviceFour from "./../../assets/images/service-04.svg";
const ServiceCardSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="service-card-slider mt-5">
      <div className="service-card">
        <Slider {...settings}>
          <Card>
            <Card.Body>
              <Card.Title>Residential cleaning</Card.Title>
              <div className="service-icon mt-5">
                <Card.Img src={serviceOne} varient="bottom" />
              </div>
              <div className="mt-5 text-left">
                <ul>
                  <li>One time clean</li>
                  <li>Regular cleans</li>
                  <li>Casual clean</li>
                  <li>Move-in or Move-out clean</li>
                  <li>Post renovation clean</li>
                  <li>Housekeeping services</li>
                </ul>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Commercial cleaning</Card.Title>
              <div className="service-icon mt-5">
                <Card.Img src={serviceTwo} varient="bottom" />
              </div>
              <div className="mt-5 text-left">
                <ul>
                  <li>Offices</li>
                  <li>Warehouses</li>
                  <li>Shops</li>
                  <li>Restaurants</li>
                  <li>Post construction clean</li>
                  <li>Common areas in buildings</li>
                </ul>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title>Carpet cleaning</Card.Title>
              <div className="service-icon mt-5">
                <Card.Img src={serviceThree} varient="bottom" />
              </div>
              <div className="mt-5 text-left">
                <ul>
                  <li>Homes</li>
                  <li>Offices</li>
                  <li>Warehouses</li>
                  <li>Shops and Restaurants</li>
                  <li>Post construction clean</li>
                  <li>Common areas in buildings</li>
                </ul>
              </div>
            </Card.Body>
          </Card>
        </Slider>
      </div>
    </div>
  );
};

export default ServiceCardSlider;
